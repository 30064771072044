import '@css/src.css';
import ComponentLoader, { Component } from './loader';

const componentLoader = new ComponentLoader();
const components: Component[] = [
  {
    name: 'lazysizes',
    selector: '.lazyload',
  },
  {
    name: 'vidstack',
    selector: 'media-player',
  },
  {
    name: 'flatpickr',
    selector: '.inline_picker'
  },
  {
    name: 'glightbox',
    selector: '.video-popup, .glightbox',
  },
  {
    name: 'swiper',
    selector: '[data-swiper]',
  },
  {
    name: 'alpine',
    selector: '[x-data]'
  }
];

for (let component of components) {
  componentLoader.loadComponent(component.name, component.selector, component.plugins ?? []);
}

// @ts-expect-error apparently import.meta is not supported in es2023?
if (import.meta.hot) {
  // @ts-expect-error apparently import.meta is not supported in es2023?
  import.meta.hot.accept();
}

// NOTE(lexisother): DOUBLE CHECK IF ANYTHING IN THIS BLOCK IS REQUIRED.
//  I HAVE NO IDEA WHAT HALF OF THIS STUFF IS FOR
if (false) {
  // Listen for all clicks on the document
  document.addEventListener('click', function (e) {
    const target = e.target! as HTMLElement;

    var fullClickElm = target.closest('.fullClick');
    if (fullClickElm != null) {
      // Bail if it's a link
      if (target.tagName != 'A') return;

      let linkElm = fullClickElm.getElementsByTagName('A').length > 0 ? fullClickElm.getElementsByTagName('A')[0] as HTMLElement : null;
      if(linkElm != null) {
        let linkTarget = linkElm.getAttribute('target');
        let url = linkElm.getAttribute('href')!;
        let text = linkElm.innerText;
        if(linkTarget == '_blank') {
          window.open(url);
        } else {
          pushToDataLayer(text,url);
          window.location.href = url;
        }
      }

    } else if (target.classList.contains('filter__toggle')) {
      // Bail if it's not a .nav-link
      //if (!event.target.classList.contains('filter__toggle')) return;

      // Toggle the active class
      target.classList.toggle('open')

      var filterMenu = target.nextSibling as HTMLElement;
      if(filterMenu && filterMenu.classList.contains('filter__menu')) {
        var hasVerticalScrollbar = filterMenu.scrollHeight > filterMenu.clientHeight;
        if(hasVerticalScrollbar) {
          filterMenu.classList.add('hasScroll');
        }
      }

      // Get all nav links
      var links = document.querySelectorAll('.filter__toggle')

      // Loop through each link
      for (var i = 0; i < links.length; i++) {
        // If the link is the one clicked, skip it
        if (links[i] === target) continue

        // Remove the .active class
        links[i].classList.remove('open')
      }
    }
  }, false)

  function pushToDataLayer(event: string, value: string) {
    var page = window.location.pathname;
    var dataObject = {
      'event': event,
      'category': 'click',
      'label': value,
      'currentpage': page
    };
    if(typeof window.dataLayer != 'undefined'){
      window.dataLayer.push(dataObject);
    }
  }
}

// TODO: what's the deal with this?????
// var newsletterForm = document.getElementById('newsletterForm');
// if(newsletterForm) {
//   newsletterForm.addEventListener("submit", function(e) {
//     e.preventDefault();
//     var emailInput = document.getElementById('newsletterEmail');
//     var data = {};
//     data['email'] = emailInput.value;
//     data[csrfTokenName] = csrfTokenValue;
//     ajax.get('/actions/collection-module/form/subscribe-newsletter', data, function() {
//       var newsletterForm = document.getElementById('newsletterForm');
//       var newsletterMessage = document.getElementById('newsletterSuccesMessage');
//       newsletterForm.classList.add('hidden');
//       newsletterMessage.classList.remove('hidden');
//       return false;
//     });
//   });
// }
// var ajax = {};
// ajax.x = function () {
//   if (typeof XMLHttpRequest !== 'undefined') {
//     return new XMLHttpRequest();
//   }
//   var versions = [
//     "MSXML2.XmlHttp.6.0",
//     "MSXML2.XmlHttp.5.0",
//     "MSXML2.XmlHttp.4.0",
//     "MSXML2.XmlHttp.3.0",
//     "MSXML2.XmlHttp.2.0",
//     "Microsoft.XmlHttp"
//   ];

//   var xhr;
//   for (var i = 0; i < versions.length; i++) {
//     try {
//       xhr = new ActiveXObject(versions[i]);
//       break;
//     } catch (e) {
//     }
//   }
//   return xhr;
// };

// ajax.send = function (url, callback, method, data, async) {
//   if (async === undefined) {
//     async = true;
//   }
//   var x = ajax.x();
//   x.open(method, url, async);
//   x.onreadystatechange = function () {
//     if (x.readyState == 4) {
//       callback(x.responseText)
//     }
//   };
//   if (method == 'POST') {
//     x.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
//   }
//   x.send(data)
// };

// ajax.get = function (url, data, callback, async) {
//   var query = [];
//   for (var key in data) {
//     query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
//   }
//   ajax.send(url + (query.length ? '?' + query.join('&') : ''), callback, 'GET', null, async)
// };

// ajax.post = function (url, data, callback, async) {
//   var query = [];
//   for (var key in data) {
//     query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
//   }
//   ajax.send(url, callback, 'POST', query.join('&'), async)
// };